<template>
  <div>
    <div class="widnow_wrapper">
      <div id="right_slow">

        <div id="up">
          <!-- <div id="online_chat_mk" class="chat_online_head" @click="close">
            <div class="wx_position">
              <img src="../assets/img/index/wx.png" alt="">
            </div>
            <p class="wx_p">在线</p>
            <p class="wx_p">咨询</p>
          </div> -->
          <!-- <div class="bottom_mid_line"></div> -->
          <div class="wx_hover">
            <div class="bg_position">
            </div>
            <p class="wx_p">微信</p>
            <p class="wx_p">咨询</p>
            <div class="right_qr_bouns">
              <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42534fb3387702299482451322/f0.jpg" alt="">
            </div>
          </div>
          <div class="bottom_mid_line"></div>
          <div class="tel_hover">
            <div class="bg_position_tel"></div>
            <p class="tel_p">电话</p>
            <p class="tel_p">咨询</p>
            <div class="tel_chat">
              <div class="tel_chat_title">
                电话沟通
              </div>
              <div class="tel_chat_title_num">
                138-8056-3034

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右下角聊天框 -->
      <!-- <transition name="is_show_chat_window" appear>
        <div id="chat_window" v-show="is_show_chat_window">
          <div class="head_chat">
            <img src="../assets/img/index/chat_logo.png" alt="">
            <img id="chat_close" style="cursor: pointer;" src="../assets/img/index/close.png" alt="" @click="close">
          </div>
          <Chat></Chat>
        </div>
      </transition> -->
    </div>

    <div class="mobile_wrapper">
      <div class="m_icon">
        <img class="click_show" @click="is_show_chat_mobile = !is_show_chat_mobile" src="../assets/img/mobile_icon.png" alt="">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight" leave-active-class="animate__fadeOutRight">
          <div class="list_main_wrapper" v-show="is_show_chat_mobile">
            <div class="list_wrapper">
              <a class="wx_mask_show">
                <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut">
                  <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/470173a0387702299482658245/f0.png" alt="" v-show="is_show_wx_img">
                </transition>
                <div @click="is_show_wx_img = !is_show_wx_img"><img src="../assets/img/mobile-wx.png" alt=""> 微信咨询</div>
              </a>
              <a href="tel:13880563034">
                <div><img src="../assets/img/mobile-tel.png" alt=""> 电话咨询
                </div>
              </a>
              <!-- <router-link to='/chat'>
                <div>
                  <img src="../assets/img/mobile-chat.png" alt=""> 在线咨询
                </div>
              </router-link> -->
            </div>
            <img @click="is_show_chat_mobile = false" class="color-close" src="../assets/img/color-close.png" alt="">
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import TIM from "@/utils/tim-js.js";

import Chat from "@/components/chat/index";

export default {
  // 右侧悬浮框
  name: "RightSuspension",
  components: { Chat },
  data() {
    return {
      // 控制pc端聊天框显示隐藏
      is_show_chat_window: true,
      // 控制手机端右下角导航栏显示隐藏
      is_show_chat_mobile: false,
      // 控制手机端右下角导航栏中微信二维码显示隐藏
      is_show_wx_img: false,
      // 控制pc端聊天框加载更多文字的显示隐藏
      is_load_more: true,
      // 保存tim实例
      tim: null,
      serviceId: undefined,
      nextReqMessageID: undefined,
      // 聊天框文字
      content: "",
      // 历史聊天记录
      messageList: [],
    };
  },
  mounted() {
    let options = {
      SDKAppID: 1400555298, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
    };
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    this.tim = TIM.create(options);
    // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
    this.tim.setLogLevel(6); // 普通级别，日志量较多，接入时建议使用
    // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
    let baseUrl = "http://api.yulindata.net/";
    let chatContent = this.$refs.chatContent;

    axios
      .get("http://api.yulindata.net/api/IM/GetServiceSeat?IsSeat=0")
      .then((res) => {
        let { data } = res;
        if (data.IsSuccess) {
          this.serviceId = data.Entities[0].To;
          let promise = this.tim.login({
            userID: data.Entities[0].From.toString(),
            userSig: data.Entities[0].UserSig,
          });

          promise
            .then(function (imResponse) {
              if (imResponse.data.repeatLogin === true) {
                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                console.log(imResponse.data.errorInfo);
              }
            })
            .catch(function (imError) {
              console.warn("login error:", imError); // 登录失败的相关信息
            });

          let _this = this;

          let onMessageReceived = function (event) {
            // event.data - 存储 Message 对象的数组 - [Message]
            _this.messageList.push(event.data[0]);

            _this.scrolltest();

            let promise = tim.setMessageRead({
              conversationID: event.data[0].conversationID,
            });
            promise
              .then(function (imResponse) {
                // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
              })
              .catch(function (imError) {
                // 已读上报失败
                console.warn("setMessageRead error:", imError);
              });
          };

          _this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);

          _this.tim.on(TIM.EVENT.SDK_READY, function (event) {
            // 打开某个会话时，第一次拉取消息列表
            let promise = _this.tim.getMessageList({
              conversationID: `C2C${_this.serviceId}`,
              count: 15,
            });
            promise.then(function (imResponse) {
              const messageList = imResponse.data.messageList; // 消息列表。

              _this.messageList = messageList;

              if (messageList.length !== 0) {
                let promise = _this.tim.setMessageRead({
                  conversationID: messageList[0].conversationID,
                });
                promise
                  .then(function (imResponse) {
                    // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
                  })
                  .catch(function (imError) {
                    // 已读上报失败
                    console.warn("setMessageRead error:", imError);
                  });
              }

              _this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
              _this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
              _this.scrolltest();
            });
          });
        } else {
          window.alert(data.Message);
        }
      })
      .catch((error) => {});
  },
  methods: {
    // 显示隐藏在线聊天
    close() {
      this.is_show_chat_window = !this.is_show_chat_window;
    },

    // 发送
    sendMsg() {
      let _this = this;
      if (this.content !== "") {
        // 1. 创建消息实例，接口返回的实例可以上屏
        let message = _this.tim.createTextMessage({
          to: _this.serviceId,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            text: _this.content,
          },
        });

        // 2. 发送消息
        let promise = _this.tim.sendMessage(message);

        promise
          .then(function (imResponse) {
            let datasend = [];
            datasend.push({
              MsgId: imResponse.data.message.ID,
              IMFrom: imResponse.data.message.from,
              IMTo: imResponse.data.message.to,
              MsgContent: imResponse.data.message.payload.text,
              TimeStamp: imResponse.data.message.time,
            });

            _this.messageList.push(imResponse.data.message);

            _this.scrolltest();

            // 清空
            _this.content = "";
          })
          .catch(function (imError) {
            // 发送失败
            console.warn("sendMessage error:", imError);
          });
      } else {
        alert("请输入内容");
      }
    },

    // 加载更多
    load_more() {
      let _this = this;
      let scrollHeight = this.$refs.chatContent.scrollHeight;
      console.log(scrollHeight);

      // 打开某个会话时，第一次拉取消息列表
      let promise = _this.tim.getMessageList({
        conversationID: `C2C${_this.serviceId}`,
        count: 15,
        nextReqMessageID: _this.nextReqMessageID,
      });
      promise.then(function (imResponse) {
        _this.messageList.unshift(...imResponse.data.messageList); // 消息列表。

        _this.$nextTick(() => {
          _this.$refs.chatContent.scrollTop =
            _this.$refs.chatContent.scrollHeight - scrollHeight;
        });

        _this.nextReqMessageID = imResponse.datanextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        _this.is_load_more = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        if (_this.is_load_more) _this.is_load_more = false;
      });
      //   _this.scrolltest()
    },

    // 自动置底
    scrolltest() {
      this.$nextTick(() => {
        var div = this.$refs.chatContent;
        if (div && div.scrollHeight) {
          div.scrollTop = div.scrollHeight;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.widnow_wrapper {
  #right_slow {
    position: fixed;
    right: 50px;
    top: 20%;
    z-index: 99;
    #up {
      width: 48px;
      padding: 20px 0px;
      background-image: url("../assets/img/fix_bg.png");
      background-size: 100% 100%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & > div > p {
        font-size: 12px;
        font-weight: 300;
        color: #666666;
        line-height: 16px;
      }

      & > div:not(:nth-child(2)) {
        cursor: pointer;
      }

      .chat_online_head {
        margin-bottom: 10px;
      }

      .wx_position {
        width: 26px;
        height: 22px;
        margin-bottom: 6px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .bottom_mid_line {
        width: 28px;
        height: 1px;
        background: #555555;
        opacity: 0.3;
        margin-bottom: 10px;
      }

      .wx_hover {
        margin-bottom: 10px;
        position: relative;
        &:hover .bg_position {
          background-position: -43px -8px;
        }
        &:hover .wx_p {
          color: #0058a2 !important;
        }
        &:hover .right_qr_bouns {
          transform: scale(1, 1);
        }
        .bg_position {
          background-image: url("../assets/img/i-img.png");
          background-position: -5px -8px;
          width: 26px;
          height: 22px;
          overflow: hidden;
          margin-bottom: 6px;
          transition: all 0.3s;
        }

        .right_qr_bouns {
          position: absolute;
          left: -196px;
          top: 50%;
          width: 190px;
          height: 183px;
          margin-top: -91.5px;
          background-image: url("../assets/img/index/bowns.png");
          background-size: 100% 100%;
          z-index: 999999999999;
          transform: scale(0, 0);
          transition: all 0.3s;
          img {
            width: 150px;
            height: 150px;
            margin-left: 15px;
            padding-top: 16px;
          }
        }
      }

      .tel_hover {
        position: relative;

        .bg_position_tel {
          background-image: url("../assets/img/t-img.png");
          background-position: -7px -8px;
          width: 22px;
          height: 22px;
          overflow: hidden;
          margin-bottom: 6px;
          transition: all 0.3s;
        }

        .tel_chat {
          position: absolute;
          left: -164px;
          top: 50%;
          width: 140px;
          height: 74px;
          margin-top: -37px;
          background-image: url("../assets/img/tel-bouns.png");
          background-size: 100% 100%;
          z-index: 999999999999;
          transform: scale(0, 0);
          transition: all 0.3s;
          padding-left: 18px;
          padding-top: 18px;
          .tel_chat_title {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            margin-bottom: 10px;
          }

          .tel_chat_title_num {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 400;
            color: #0058a2;
            line-height: 16px;
          }
        }
        &:hover .tel_chat {
          transform: scale(1, 1);
        }

        &:hover .bg_position_tel {
          background-position: -44px -8px;
        }

        &:hover .tel_p {
          color: #0058a2 !important;
        }
      }
    }
  }
  #chat_window {
    height: 400px;
    width: 360px;
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    .head_chat {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px;
      background: #1740df;
    }
    .chat-content {
      height: 250px;
      background: #fafafa;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 20px;
      box-sizing: border-box;
      position: relative;
      padding-top: 21px;
      .load_more {
        font-size: 10px;
        color: #9b9b9b;
        text-align: center;
        cursor: pointer;
      }
      .other-msg {
        text-align: left;
        padding: 0 10px;
        margin-top: 10px;
        animation: show_left 0.5s;
        .service_title {
          font-size: 12px;
          margin-bottom: 6px;
          font-weight: 400;
          color: #9b9b9b;
        }
        span {
          display: inline-block;
          padding: 15px 20px 15px 15px;
          background-color: #e4e4e4;
          max-width: 100%;
          white-space: pre-wrap;
          white-space: pre-line;
          word-break: break-word;
          text-align: left;
          color: #525252;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          -webkit-animation: slide-right 0.2s ease-in-out;
          animation: slide-right 0.2s ease-in-out;
          font-size: 14px;
        }
      }

      .self-msg {
        text-align: right;
        padding: 0 10px;
        margin-top: 10px;
        animation: show_right 0.5s;
        span {
          display: inline-block;
          padding: 15px 20px 15px 15px;
          background-color: #4592e2;
          max-width: 100%;
          white-space: pre-wrap;
          white-space: pre-line;
          word-break: break-word;
          text-align: left;
          color: #ffffff;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          -webkit-animation: slide-left 0.2s ease-in-out;
          animation: slide-left 0.2s ease-in-out;
          font-size: 14px;
        }
      }

      @keyframes show_right {
        from {
          transform: translateX(100%);
        }

        to {
          transform: translateX(0);
        }
      }

      @keyframes show_left {
        from {
          transform: translateX(-100%);
        }

        to {
          transform: translateX(0);
        }
      }
    }

    .chat_input {
      height: 100px;
      background: #fff;
      border: 1px solid #d6d6d6;
      position: relative;
      #chat_area {
        border: none;
        outline: none;
        font-size: 14px;
      }
      .chatt_send_area {
        width: 56px;
        height: 30px;
        background: #1740df;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
      }
    }
  }

  /* 隐藏 */
  .is_show_chat_window-enter-active {
    animation: hidden 0.5s;
  }

  /* 显示 */
  .is_show_chat_window-leave-active {
    animation: hidden 0.5s reverse;
  }

  @keyframes hidden {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.mobile_wrapper {
  display: none;
}

@media all and (max-width: 1080px) {
  .mobile_wrapper {
    display: block;
    .m_icon {
      position: fixed;
      right: 10px;
      bottom: 20px;
      z-index: 99999;
      & > img {
        width: 60px;
        height: 60px;
      }
      .list_main_wrapper {
        position: absolute;
        top: 50%;
        margin-top: -35px;
        left: -250px;
        // display: none;
        z-index: 99999;
        .list_wrapper {
          width: 200px;
          height: 50px;
          background-image: url("../assets/img/mobile-bg-chat.png");
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          justify-content: space-around;
          padding: 10px 30px;
          div {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-size: 12px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #1740df;
            img {
              width: 30px;
              margin-bottom: 6px;
            }
          }

          .wx_mask_show {
            position: relative;
            & > img {
              position: absolute;
              top: -210px;
              left: -56px;
            }
          }
        }
        .color-close {
          position: absolute;
          left: -2px;
          top: -4px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .widnow_wrapper {
    display: none;
  }
}
</style>